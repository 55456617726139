import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import breakpointsMedia from 'theme/utils/breakpointsMedia';

const newsletterFormIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACuSURBVHgB7dfRDYIwFIXhU+MAjuAIbiAjOELdgA1kA91AncgRHAE3uB4TIljLG7QNOX/SNISSfkl5KaCUimdmW44zh0cJEfK0vityR0Rrv+VFEVDbf9lRjVBCCVU6ah35+MSpwvx57gXn3HF0BRccLH3V0LAKTC+kbzN8cOFbihtOe0zfLtyc3XlkHqn7/JeRo7ohR8III8wiMR2oLQbTgR7FYIDvRfHCUUMp1fcGIxxOJCX6LXkAAAAASUVORK5CYII=';

const GlobalStyle = createGlobalStyle`
  :root {
    --primaryMap: #d8d8d8;
    --homeMap: #ffba39;
    --baseMap: rgba(0, 0, 0, 0.5);
    --base2Map: #949494;
    --displayStatusMap: unset;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  ${normalize}

  ${({ theme }) => css`
    html {
      font-size: 62.5%; /* =10px */
    }

    body {
      font-size: 1.6rem;
      font-family: ${theme.fontFamily};
      overscroll-behavior-x: none;

      &.hidden {
        overflow: hidden;
      }
    }
  `}

  #__next {
    width: 100%;
    min-width: 100%;
  }

  .skeleton-box {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #f3f3f3;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        #ffffff00 0,
        #ffffff33 20%,
        #ffffff80 60%,
        #ffffff00
      );
      animation: shimmer 3s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .image-fill {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: transparent;
    object-fit: cover;
  }

  ${({ theme }) => css`
    .bricks--component {
      min-width: auto !important;

      .bricks--section {
        padding-top: 1rem !important;
        padding-bottom: 1.2rem !important;
        background-image: none !important;
        background-color: ${theme.colors.base['950']};

        ${breakpointsMedia({
          xl: css`
            padding-top: 3rem !important;
            padding-bottom: 3rem !important;
          `,
        })}

        .bricks--row {
          align-items: center;
          max-width: 1376px !important;
          min-height: auto !important;

          ${breakpointsMedia({
            md: css`
              flex-direction: column;
            `,
            xl: css`
              flex-direction: row;
            `,
          })}

          /* First column */
            #rd-column-luhb2wjd {
            flex: none;

            ${breakpointsMedia({
              xl: css`
                width: 488px;
              `,
            })}

            & > div {
              min-height: auto;
            }
          }

          /* Second column */
          #rd-column-luhazmnb {
            width: 100%;
            flex: 1 !important;

            ${breakpointsMedia({
              xl: css`
                width: auto;
              `,
            })}

            & > div {
              padding: 0 8px !important;
            }

            .bricks-form#rd-form-luhazmne {
              max-width: none;

              form {
                gap: 1.6rem;
                display: flex;
                justify-content: end;
                flex-direction: column;

                ${breakpointsMedia({
                  xl: css`
                    flex-direction: row;
                  `,
                })}

                input {
                  height: 4.8rem;
                }
              }
            }

            .bricks-form__field {
              margin-bottom: 0 !important;

              input {
                margin-left: 3px;
                width: calc(100% - 4px);

                ${breakpointsMedia({
                  xl: css`
                    width: auto;
                    margin-left: 0;
                  `,
                })}
              }
            }

            .bricks-form__fieldset {
              width: calc(100% - 122px);

              ${breakpointsMedia({
                xl: css`
                  width: 32rem;
                `,
              })}
            }

            .bricks-form__submit {
              width: 120px;
              position: absolute;
              right: 14px;

              ${breakpointsMedia({
                xl: css`
                  width: 32rem;
                  position: relative;
                  right: auto;
                `,
              })}

              button {
                height: 5rem;
                padding: 12px;
                max-width: none;
                font-size: 16px;
                text-align: left;
                font-weight: 700;
                line-height: 20px;
                font-style: normal;
                border-radius: 4px;
                position: relative;
                text-transform: uppercase;
                font-family: 'Barlow', sans-serif;
                color: ${theme.colors.base['00']};
                background: linear-gradient(
                    89deg,
                    #8f6ead 0%,
                    #4bb6cb 66.88%,
                    #62ba97 100%
                  ),
                  #e441ff;

                ${breakpointsMedia({
                  xl: css`
                    height: 4%.8;
                  `,
                })}

                &::before {
                  top: 50%;
                  content: '';
                  right: 12px;
                  width: 2.4rem;
                  height: 2.4rem;
                  position: absolute;
                  pointer-events: none;
                  background-size: contain;
                  transform: translateY(-50%);
                  background-image: url(${newsletterFormIcon});
                }
              }
            }
          }
        }

        .bricks--component-text {
          max-width: 100%;

          h4 {
            padding: 0 50px 0 0 !important;
            margin-bottom: 0.8rem !important;

            ${breakpointsMedia({
              xl: css`
                padding: 0 5px !important;
                margin-bottom: 0 !important;
              `,
            })}

            span {
              font-size: 1.4rem !important;
              line-height: 1.8rem !important;
              color: ${theme.colors.base['00']} !important;
              font-family: 'Barlow Bold', sans-serif !important;

              ${breakpointsMedia({
                xl: css`
                  font-size: 2.4rem !important;
                  line-height: 3.2rem !important;
                `,
              })}
            }
          }
        }

        .bricks--column#rd-column-luhazmnb {
          flex: 1 0 auto;
          max-height: none;
          padding: 0 !important;

          ${breakpointsMedia({
            xl: css`
              max-height: 4.8rem;
            `,
          })}

          & > div {
            max-height: none;
            min-height: auto;

            ${breakpointsMedia({
              xl: css`
                max-height: 4.8rem;
              `,
            })}
          }
        }
      }

      .bricks--close--button {
        top: 8px !important;
        left: 50% !important;
        right: auto !important;
        width: 1376px !important;
        padding: 0 10px !important;
        max-width: 100% !important;
        justify-content: end !important;
        transform: translateX(-50%) !important;

        ${breakpointsMedia({
          xl: css`
            top: 5px !important;
          `,
        })}

        svg {
          fill: ${theme.colors.base['00']} !important;
        }
      }
    }

    .link-underline {
      position: relative;
      text-decoration: none;

      &:before {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        content: '';
        display: block;
        position: absolute;
        transform: scaleX(0);
        transition: transform 0.3s ease;
        background-color: ${theme.colors.base['900']};
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
  `}
`;

export default GlobalStyle;
